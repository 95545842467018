import React from 'react';

const ServicesSecton = () => 
{

    const services = [
        {
            icon:"flaticon-004-solar-panel",
            title: "Wind generators",
            content: "A Leading Supplier Of Solar Materials For Manufacturers, Installers & Contractors.",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        },
        {
            icon:"flaticon-039-wind-mill",
            title: "Solar pv materials",
            content: "The great thing about solar panels is that they do not require a lot of maintenance. However, still important to get them checked regularly",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        },
        {
            icon:"flaticon-013-hydro-power",
            title: "Battery materials",
            content: "Battery materials",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        },
        {
            icon:"flaticon-004-solar-panel",
            title: "Hydropower plants",
            content: "Hydropower plants",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        },
        {
            icon:"flaticon-039-wind-mill",
            title: "Fossil resources",
            content: "Fossil resources",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        },
        {
            icon: "flaticon-013-hydro-power",
            title: "Charge controllers",
            content: "Charge controller",
            service_list: [
                "Installation",
                "Maintnance",
            ]
        }
    ];
    return(
        <React.Fragment>
             <section className="services services-4 bg-grey" id="services-4">
                <div className="container">
                <div className="heading heading-3 text-center">
                    <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <p className="heading-subtitle">Energize Society With Sustainable Energy!</p>
                        <h2 className="heading-title">A Leading Supplier Of Solar Materials For Manufacturers, Installers & Contractors.</h2>
                    </div>
                    
                    </div>
                
                </div>
                
                <div className="row">

                    {services.map( (service) => (
                            <div key={service.title} className="col-12 col-md-6 col-lg-4">
                               <div className="service-panel">
                                   <div className="service-icon"><i className={service.icon}></i></div>
                                   <div className="service-content">
                                   <h4><a href="#services-turbines.html">{service.title}</a></h4>
                                   <p>{service.content}</p>
                                   <ul className="list-unstyled advantages-list"> 
                                        {service.service_list.map( (list_item) => (
                                            <li key={list_item}>{list_item}</li>
                                        )) }
                                   </ul><a className="btn btn--secondary" href="/khutla-green-pricing"> more <i className="energia-arrow-right"></i></a>
                                   </div>
                               </div>
                               
                           </div>
                     ))}
       
                    </div>
                
                </div>
      
            </section>
        </React.Fragment>
    );
}

export default ServicesSecton;