import React from "react";
import Banner from "../../components/Banner";
import GallerySection from '../../sections/gallery/index';

const GalleryPage = () => {

    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "Company", link:"/about"},
        { "label": "Gallery", active: true }
    ];

    return(
    <React.Fragment>

        <Banner title="Gallery" description="Khutla green" breadcrumb={ breadcrumb } />

        <GallerySection />

    </React.Fragment>);
} 

export default GalleryPage;