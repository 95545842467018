import React from "react";
import Banner from "../../components/Banner";
import HomeAboutSection from "../../sections/about";
import Features from "../../sections/Features";
import RecentProjectsSection from '../../sections/recent-projects/index';

const AboutPage = () => {

    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "Company", link:"/about"},
        { "label": "About Us", active: true }
    ];

    return(
    <React.Fragment>

        <Banner title="About Us" description="Khutla green" breadcrumb={ breadcrumb } />

        <HomeAboutSection />

        <Features showVideo={false} />

        <RecentProjectsSection />

    </React.Fragment>);
} 

export default AboutPage;