import React from 'react';
import Slide from './Slide';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = (props) => {

  const Owloptions = {
    loop: false,
    autoplay: false,
    margin: 25,
    nav: false,
    dots: true,
    dotsSpeed: "3000",
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    rtl: "true",
    responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 3,
            }
        }
    };

    const { packages } = props;
  
    return(
    <React.Fragment>

        <OwlCarousel className="carousel owl-carousel carousel-dots owl-loaded owl-drag" {...Owloptions}>
            {packages.map( (pkg) => (                
                <Slide key={pkg.title} title={pkg.title} categoryOption={ pkg.categoryOption }  content={pkg.content}/>
             ))
            } 
        </OwlCarousel>
    
    </React.Fragment>
    );
}

export default Slider;