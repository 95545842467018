import React from "react";
import Banner from "../../components/Banner";
import PricingPackages from '../../sections/pckages/index';

const PricingPage = () => {

    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "Company", link:"/about"},
        { "label": "Pricing", active: true }
    ];

    return(
    <React.Fragment>

        <Banner 
            title="Pricing Packages" 
            description="We pride ourselves in providing the qualitty services across the entire energy value chain. We support our customers on their way to a more sustainable future." 
            breadcrumb={ breadcrumb } />

        <PricingPackages />

    </React.Fragment>);
} 

export default PricingPage;