import React from "react";
import Slider from '../../components/slider/Services/index';

const Services = () => {


    return(
    <section className="services services-1 bg-grey" id="services-1">
        <div className="container">
            
            <div className="heading heading-3 text-center">
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        <p className="heading-subtitle">Energize Society With Sustainable Energy!</p>
                        <h2 className="heading-title">Khutla Green Services</h2>
                    </div>
                </div>
            </div>
        
            <Slider />
        
        </div>
    </section>
    );
}

export default Services;