import React from 'react';

const SearchBar = () => {

    return(
        <div className="module-content module-search-warp">
            <div className="pos-vertical-center">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                    <form className="form-search">
                    <input className="form-control" type="text" placeholder="search keywords and press enter"/>
                    <button></button>
                    </form>  
                </div>         
                </div>       
            </div>      
            </div><a className="module-cancel" href="/"><i className="fas fa-times"></i></a>
        
        </div>
    );
}

export default SearchBar;