import React from 'react'
import { Link } from 'react-router-dom';

const Slide = (props) => {
    const {img, title, content} = props
 
    const backgroundImageStyle =  {
        backgroundImage: 
            "url('"+ img +"')"
    }

    return (

       

        <div className="slide bg-overlay bg-overlay-dark-slider"
            style={backgroundImageStyle}>
           
                
          
            <div className="container" style={{ zIndex: 4 }}>
                <div className="row">
                    <div className="col-12 col-lg-7">
                    <div className="slide-content">
                        <h1 className="slide-headline">{title}</h1>
                        <p className="slide-desc">{content}</p>
                        <div className="slide-action">
                        <div className="slide-list"> 
                            <div className="icon"> <i className="flaticon-040-green-energy"></i></div>
                            <div className="icon"> <i className="flaticon-020-factory"></i></div>
                            <div className="icon"> <i className="flaticon-031-nuclear-plant"></i></div>
                        </div>
                        <Link to="/" className="btn btn--primary"><span>our services</span><i className="energia-arrow-right"></i></Link>
                
                        </div>
                    </div>
                    
                    </div>
                    <div className="col-12 col-lg-3 offset-lg-2 d-flex"> 
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Slide
