
import React, { useEffect }  from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

//layouts
import HomeLayout from './layouts/HomeLayout';

//pages
import HomePage from './pages/homepage';
import AboutPage from './pages/aboutpage';
import GalleryPage from './pages/gallery';
import PricingPage from './pages/pricing';
import QuotePage from './pages/quoting';
import ContactPage from './pages/contact';
import ServicesPage from './pages/services';
import DefaultPage from './pages/404-page';
import FAQsPage from './pages/faqs';

function App() {

  useEffect(() => {

    //fetchCategories()
   }, [])
 

  return (
    <BrowserRouter>
    <Routes>
    
       <Route exact path="/"  element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about-khutla-green" element={<AboutPage />} />
          <Route path="khutla-green-gallery" element={<GalleryPage /> } />
          <Route path="khutla-green-pricing" element={ <PricingPage />} />
          <Route path="khutla-green-services" element={<ServicesPage />} />
          <Route path="request-quote" element={<QuotePage/>} />
          <Route path="contact-khutla-green" element={<ContactPage/>} />
          <Route path="faqs" element={<FAQsPage/> } />

          <Route  path="*" element={<DefaultPage />} />
       </Route>


    </Routes>
 </BrowserRouter>
  );
}

export default App;
