import React from 'react';
import './style.css';

const Slide = (props) => {
    const {image, category, title, content, link} = props
 

    return (     
        <div>
          <div className="project-panel" data-hover="">
            <div className="project-panel-holder" >
              <div className="project-img"><img src={ image } alt={title} /></div>
            
              <div className="project-content"> 
                <div className="project-cat"><a href="projects-standard.html">{ category }</a>
                </div>
                <div className="project-title">
                  <h4><a href={ link }>{ title} </a></h4>
                </div>
                <div className="project-desc">
                  <p>{ content } </p>
                </div>
                <div className="project-more"> <a className="btn btn--bordered btn--white" href={ link }>explore more <i className="energia-arrow-right"></i></a></div>
              </div>
            
            </div>
          </div>
        </div>

    )
}

export default Slide
