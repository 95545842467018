import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

const Nav = props => {

    return(
        <nav className="navbar navbar-expand-lg navbar-sticky" id="primary-menu"><a className="navbar-brand" href="/">
            <img className="logo logo-dark" src="/assets/images/logo/logo-dark.png" alt="Khutla Green"/><img className="logo logo-mobile" src="/assets/images/logo/logo-mobile.png" alt="Khutla Green"/></a>
          <div className="module-holder module-holder-phone">
            <div className="module module-search">
              <div className="module-icon module-icon-search"><i className="energia-search-Icon"></i></div>
            </div>

         
  
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
         
          </div>

          <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav me-auto">
                          <li className="nav-item active" id="home" data-hover=""><a href="/"><span>Home</span></a></li>
                        
                          <li className="nav-item has-dropdown" data-hover=""><a className="dropdown-toggle" href="/about-khutla-green" data-toggle="dropdown"><span>company</span></a>
                                        <ul className="dropdown-menu">
                                          <li className="nav-item"><a href="/about-khutla-green"><span>about us</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-gallery"><span>our gallery</span></a></li>
                                     
                                        </ul>
                          </li>
                          <li className="nav-item has-dropdown" id="departments" data-hover=""><a className="dropdown-toggle" href="/khutla-green-services" data-toggle="dropdown"><span>services</span></a>
                                        <ul className="dropdown-menu">
                                          <li className="nav-item"><a href="/khutla-green-services"><span>wind generators</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-services"><span>solar pv materials</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-services"><span>battery materials</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-services"><span>hydropower plants</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-services"><span>fossil resources</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-services"><span>charge controllers</span></a></li>
                                        </ul>
                          </li>
                          <li className="nav-item" data-hover=""><a  href="/khutla-green-gallery" ><span>projects</span></a>
      
                          </li>
                          <li className="nav-item has-dropdown" data-hover=""><a className="dropdown-toggle" href="/khutla-green-pricing" data-toggle="dropdown"><span>pricing</span></a>
                                        <ul className="dropdown-menu">
                                          <li className="nav-item"><a href="/khutla-green-pricing"><span>Starter plan</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-pricing"><span>Basic plan</span></a></li>
                                          <li className="nav-item"><a href="/khutla-green-pricing"><span>Advance plan</span></a></li>
                                        </ul>
                          </li>
                          <li className="nav-item" id="contact" data-hover=""><a href="/contact-khutla-green"><span>contact</span></a></li>
                        </ul>
            <div className="module-holder">
              <div className="module module-search">
                <div className="module-icon module-icon-search"><i className="energia-search-Icon"></i></div>
              </div>

              
           
              
              <div className="module-contact"><a className="btn btn--primary" href="/request-quote">
                   request a quote<i className="energia-arrow-right"></i></a></div>

            </div>
            
          </div>
        
        </nav>
      
    ); 
}

export default Nav;