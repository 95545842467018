import React from 'react';

const MapBanner = (props) => {
    const {mapURL} = props;
    return(
        <section className="map map-2"> 
            <div className="container">
            <div className="row">
                <div className="col">
                <h3 className="d-none">our address</h3>
                </div>
            </div>
            </div>
            <iframe title="map" src={mapURL } width="600" height="450" style={{ border: "0" }}></iframe>
        </section>
    );
}

export default MapBanner;