import React from 'react';
import Slide from './Slide';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = () => {

  const Owloptions = {
    loop: true,
    autoplay: true,
    margin: 25,
    nav: false,
    dots: true,
    dotsSpeed: "800",
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    rtl: "true",
    responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 3,
                //center: $Carousel.data('center'),
            }
        }
    };

    return(
    <React.Fragment>

        <OwlCarousel className="carousel owl-carousel carousel-dots dots-side owl-loaded owl-drag" {...Owloptions}>
            <Slide icon="flaticon-004-solar-panel" title="solar panels services" content="The great thing about solar panels is that they do not require a lot of maintenance. However, still important to get them checked regularly" />
            <Slide icon="flaticon-039-wind-mill"title="wind turbines services" content="Wind turbine is an expensive machine, we know very well how important it is that your wind turbine is always up whenever there is wind." />
            <Slide icon="flaticon-013-hydro-power"title="hydropower plants services" content="Hydropower systems are capital intensive assets can produce a significant income provided operated and maintained on high standard" />
        </OwlCarousel>
    

    </React.Fragment>
    );
}

export default Slider;