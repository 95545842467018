import React from 'react';

const HomeAboutSection = () => {
    return(
        <section className="about about-1" id="about-1">
           <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5">
                  <div className="about-img">
                    
                      
                        <div className="about-img-holder bg-overlay bg-section" style= {{ backgroundImage : "url(/assets/images/about/1.jpg)" }}>
                      
              
                    </div>
                    <div>
                    
                      <div className="counter">
                        <div className="counter-icon"> <i className="flaticon-033-plug"></i></div>
                        <div className="counter-num"> <span className="counting" data-counterup-nums="5045"> 5045</span>
                          <p></p>
                        </div>
                        <div className="counter-name">
                          <h6>facebook reach</h6>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7">
                  <div className="heading heading-1">
                    <p className="heading-subtitle heading-subtitle-bg" id="khutlagreen">Renewable and Alternative Electrical Power Solution Specialists.</p>
                    <h2 className="heading-title">Khutla Green, a 100 %  black owned company </h2>
                  </div>
                  <div className="about-block"> 
                    <div className="row">
                      <div className="col-12 col-lg-7">
                        <div className="block-left"> 
                          <p className="paragraph">South African based renewable energy and alternative power solution specialists providing energy solutions to Southern African countries and Africa at large</p>
                          <p>With issues such as load shedding, air pollution, hazardous waste, and other environmental strain created from generating electricity, Khutla Green plays a big role in mitigating this issues, and making sure we leave the earth a better place than we found it.</p><a className="btn btn--secondary" href="#page-about.html">read more <i className="energia-arrow-right"></i></a>
                        </div>
                      </div>
                      <div className="col-12 col-lg-5">
                        <div className="block-right">
                          <div className="prief-set"> 
                            <p>Khutla Green offers the best solutions in renewable energy and alternative power solutions, focusing on getting customers off the grid and protecting customers against load shedding.</p>
                            <ul className="list-unstyled advantages-list"> 
                              <li>Reliability and performance</li>
                              <li>Just-in-time manufacturing</li>
                              <li>solar material financing</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

    );
}

export default HomeAboutSection;