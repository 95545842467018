import React from 'react';

const GallerySection = () => {

    const projects = [
        { 
            title: "Project 1",
            image: "/assets/images/projects/gallery/1.jpg",
            imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
            title: "Project 2",
            image: "/assets/images/projects/gallery/2.jpg",
            imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
            title: "Project 3",
            image: "/assets/images/projects/gallery/3.jpg",
            imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
            title: "Project 4",
            image: "/assets/images/projects/gallery/4.jpg",
            imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
          title: "Project 5",
          image: "/assets/images/projects/gallery/5.jpg",
          imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
          title: "Project 6",
          image: "/assets/images/projects/gallery/6.jpg",
          imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
          title: "Project 7",
          image: "/assets/images/projects/gallery/7.jpg",
          imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
          title: "Project 8",
          image: "/assets/images/projects/gallery/8.jpg",
          imageFull: "/assets/images/projects/full/1.jpg"
        },
        { 
          title: "Project 9",
          image: "/assets/images/projects/gallery/9.jpg",
          imageFull: "/assets/images/projects/full/1.jpg"
        }
    ]

    return(
        <section className="projects projects-gallery">
        <div className="container">
          <div className="row">
            <div className="col">
              <h3 className="d-none">Our Projects at Khutla</h3>
            </div>
          </div>
          <div className="row"> 

            { projects.map( (project) => (

                <div className="col-12 col-md-6 col-lg-4 team-item " key={project.title}>
                    <div className="project-panel">
                        <div className="project-panel-holder">
                        <div className="project-img"><img src={ project.image } alt=" item"/>
                            <div className="project-hover">
                            <div className="project-action">
                                <div className="project-zoom"><i className="far fa-eye"></i>
                                    <a className="img-gallery-item" href={project.imageFull} title={ project.title }></a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                 </div>
  
             ))
            }

          </div>
          <div className="row">
            <div className="col-12 text-center"><a className="btn btn--white justify-content-center" id="loadMore" href="#">Load More</a></div>
          </div>
     
        </div>
 
      </section>
    );
}

export default GallerySection;