import React from "react";

const Footer = () => {

    return(
        <footer className="footer footer-1">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <div className="footer-widget widget-links">
                  <div className="footer-widget-title">
                    <h5>company</h5>
                  </div>
                  <div className="widget-content">
                    <ul>
                      <li><a href="/about-khutla-green">About Us</a></li>
                      <li><a href="/khutla-green-pricing">Pricing</a></li>
                      <li><a href="/khutla-green-services">Services</a></li>
                      <li><a href="/khutla-green-gallery">Our Projects</a></li>
                      <li><a href="/contact-khutla-green">contact</a></li>
                    </ul>
                  </div>
                </div>
              </div>
             
              <div className="col-12 col-sm-6 col-md-6 col-lg-2">
                <div className="footer-widget widget-links">
                  <div className="footer-widget-title">
                    <h5>Services</h5>
                  </div>
                  <div className="widget-content">
                    <ul>
                      <li><a href="/khutla-green-services">Solar Panels</a></li>
                      <li><a href="/khutla-green-services">Wind Turbines</a></li>
                      <li><a href="/khutla-green-services">Hydropower Plants</a></li>
                      <li><a href="/khutla-green-services">Fossil Resources</a></li>
                      <li><a href="/khutla-green-services">Battery Materials</a></li>
                      <li><a href="/khutla-green-services">biofuels</a></li>
                    </ul>
                  </div>
                </div>
              </div>
          
              <div className="col-12 col-sm-6 col-md-6 col-lg-5">
                <div className="footer-widget widget-links widget-icon">
                  <div className="footer-widget-title">
                    <h5>support</h5>
                  </div>
                  <div className="widget-content">
                    <ul>
                      <li><a href="/khutla-green-pricing">Terms & Conditions</a></li>
                      <li><a href="/khutla-green-pricing">Shipping Policy</a></li>
                      <li><a href="/khutla-green-pricing">Delivery Tips</a></li>
                      <li><a href="/khutla-green-pricing">Returns</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            
              <div className="col-12 col-md-6 col-lg-3">
                <div className="footer-widget widget-contact">
                  <div className="widget-content">
                    <ul> 
                      <li className="phone">+27<a href="tel:+27839482209"> 73-1480-138</a></li>
                      <li className="email">Email: <a href="mailto:nfo@khutlagreen.co.za">info@khutlagreen.co.za</a></li>
                      <li className="address">
                        <p>1 Valsbaai Street, Germiston,1450, Johannesburg.</p>
                      </li>
                      <li className="directions"><a href="/contact-khutla-green"><i className="energia-location-Icon"></i>get directions</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            
            </div>
          
          </div>
         
        </div>
       
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-copyright">
                  <div className="copyright"><span>&copy; 2023 khutla green  pty ltd. All rights reserved. </span>
                    <ul className="list-unstyled social-icons">
                      <li> <a className="share-facebook" href="https://m.facebook.com/profile.php?id=100075694462982&mibextid=LQQJ4d"><i className="energia-facebook"></i>facebook </a></li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
       
      </footer>
    );

}

export default Footer;