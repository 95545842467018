import React, { useState } from 'react';

const ContactForm   = () =>
{

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [ phone, setPhone] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [additionalInfo, setAddtionalInfo] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`The name you entered was: ${name}`)
      }

    return(


        <div className="contact-body">
            <h5 className="card-heading">get in touch</h5>
            <p className="card-desc">We take great pride in everything that we do, control over products allows us to ensure our customers receive the best quality service.</p>
            
            <form className="contactForm" method="post" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 col-md-6">
                <input className="form-control" type="text" id="contact-name" name="contact-name" placeholder="Name" required="" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-12 col-md-6">
                <input className="form-control" type="text" id="contact-email" name="contact-email" placeholder="Email" required=""
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)}
                />
                </div>
                <div className="col-12 col-md-6">
                <input className="form-control" type="text" id="contact-phone" name="contact-phone" placeholder="Phone" required=""
                      value={phone} 
                      onChange={(e) => setPhone(e.target.value)}
                />
                </div>
                <div className="col-12 col-md-6">
                <select className="form-control" id="select-1"
                    value={serviceType}  onChange={(e) => setServiceType(e.target.value)}
                >
                    <option value="default">select your services</option>
                    <option value="s1">service 1 </option>
                    <option value="s2">service 2 </option>
                </select>
                </div>
                <div className="col-12">
                <textarea className="form-control" id="contact-infos" placeholder="additional information" name="contact-infos" cols="30" rows="10"
                         value={additionalInfo} 
                         onChange={(e) => setAddtionalInfo(e.target.value)}
                ></textarea>
                </div>
                <div className="col-12">
                <button className="btn btn--secondary">submit request <i className="energia-arrow-right"></i></button>
                </div>
                <div className="col-12">
                <div className="contact-result"></div>
                </div>
            </div>
            </form>
        </div>
    );

} 

export default ContactForm;