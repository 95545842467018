import React from "react";

const TopBar = () => {

    return(
        <div className="top-bar">
            <div className="block-left"> 
            
            <div className="top-contact">
                <div className="contact-infos"><i className="energia-phone-Icon"></i>
                <div className="contact-body"> 
                    <p>phone: <a href="tel:27839482209">+27 83 948 2209</a></p>
                </div>
                </div>
                <div className="contact-infos"><i className="energia-email--icon"></i>
                <div className="contact-body"> 
                    <p>email: <a href="mailto:info@khutla.co.za">info@khutlagreen.co.za</a></p>
                </div>
                </div>
                <div className="contact-infos"><i className="energia-clock-Icon"></i>
                <div className="contact-body"> 
                    <p>hours: Mon-Fri: 8am – 5pm</p>
                </div>
                </div>
            </div>

            </div>
            <div className="block-right">
        
            <div className="social-links">
                <a className="share-facebook" href="https://m.facebook.com/profile.php?id=100075694462982&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"><i className="energia-facebook"></i></a>
            </div>
            <div className="topbar-links"><a href="https://m.facebook.com/profile.php?id=100075694462982&mibextid=LQQJ4d" target="_blank" rel="noreferrer">news & media</a><a href="/khutla-green-pricing">Pricing</a><a href="/faqs">FAQs</a></div>
            </div>
        </div>
    );

}

export default TopBar;