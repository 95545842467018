import React from 'react';
import Slide from './Slide';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = () => {

  const options = {
          loop: true,
          autoplay: true,
          margin: 0,
          nav: true,
          dots: true,
          center: true,
          dotsSpeed: 2800,
          rtl: "true",
          smartSpeed: 1000,
          responsive: {
              0: {
                  items: 1,
              },
              
              1000: {
                  items: 1,
              },
          },
          animateOut: 'fadeOut',
        };

    return(
    <React.Fragment>

      

      <section className="slider slider-1" id="slider-1">
        <div className="container-fluid pe-0 ps-0">

      
          <OwlCarousel className="slider-carousel owl-carousel carousel-navs carousel-dots owl-loaded owl-drag" loop {...options}>
             
              <Slide img="/assets/images/sliders/2.jpg"title="Alternative Power Solution" content="Protect yourself against Load Shedding" loop/>
              <Slide img="/assets/images/sliders/3.jpg"title="Rooftop solar panels" content="Protect yourself against Load Shedding" loop/>
              <Slide img="/assets/images/sliders/2.jpg" title="Renewable Energy" content="Keep the Earth Green and Clean&amp; while saving some Money." loop/>
              <Slide img="/assets/images/sliders/4.jpg"title="Best Invertors in market" content="Protect yourself against Load Shedding" loop/>
              <Slide img="/assets/images/sliders/5.jpg"title="Qualified electrical engineers" content="Protect yourself against Load Shedding" loop/>
          </OwlCarousel>
      

        </div>
      </section>
 
    </React.Fragment>
    );
}

export default Slider;