import React from 'react';
import MapBanner from '../../components/Banner/Map';
import ContactSection from '../../sections/contact';

const ContactPage = () => {

    const mapURL  = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.4525746424006!2d28.124696515594845!3d-26.24697118341877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e951017c920e889%3A0xa55f8178aba5b338!2s1%20Valsbaai%20St%2C%20Elandshaven%2C%20Germiston%2C%201450!5e0!3m2!1sen!2sza!4v1675020377994!5m2!1sen!2sza";

    return (
    <React.Fragment>
        
        <MapBanner mapURL={ mapURL } />

        <ContactSection />
        
    </React.Fragment>);
}

export default ContactPage;