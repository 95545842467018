import React from 'react';
import BreadCrumb from '../BreadCrumb';

const Banner = (props) => {

    const { title , description, breadcrumb } = props;


    return(
        <section className="page-title page-title-1" id="page-title">
            <div className="page-title-wrap bg-overlay bg-overlay-dark-2 bg-section" style = {{ backgroundImage: 'url("/assets/images/page-titles/1.jpg")' }}>
            
            <div className="container">
                <div className="row">
                <div className="col-12 col-lg-5" style={{ zIndex: "99"}}>
                    <div className="title">
                    <h1 className="title-heading">{ title }</h1>
                    <p className="title-desc">{ description } </p>
                    <div className="title-action"> <a className="btn btn--primary" href="/about-khutla-green#khutlagreen"> <span>our services</span><i className="energia-arrow-right"></i></a><a className="btn-video btn-video-2 popup-video" href="/about-khutla-green#"> <i className="fas fa-play"></i></a></div>
                    </div>
                </div>
                </div>
            </div>
            </div>
          <BreadCrumb breadcrumb={ breadcrumb } />
        
        </section>
    );

}

export default Banner;