import React from 'react';
import Banner from '../../components/Banner';

const DefaultPage = () => 
{


    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "404 - Not Found", active: true }
    ];

    return(
        <React.Fragment>

            <Banner title="Page not found" description="Khutla green" breadcrumb={ breadcrumb } />


            <section className="about about-1" id="about-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                           <h4> Page not found</h4>
                        </div>
                    </div>
                </div>

             </section>

        </React.Fragment>
    );
}


export default DefaultPage;