import React from 'react';
import './style.css';

const Slide = (props) => {
    const {icon, title, content} = props
 

    return (     
        <div>
          <div className="service-panel">
            <div className="service-icon"><i className={ icon }></i></div>
            <div className="service-content">
              <h4><a href="#services-turbines">{ title }</a></h4>
              <p> { content } </p>
              <ul className="list-unstyled advantages-list"> 
                <li>cleaning of inverter</li>
                <li>perform shading tests</li>
                <li>90 days repairs warranty</li>
              </ul><a className="btn btn--secondary" href="#services-turbines">read more <i className="energia-arrow-right"></i></a>
            </div>
          </div>
        </div>

    )
}

export default Slide
