import React from 'react';
import QouteForm from '../../components/quote-form';

const QuoteSection = () => {

    return(
        <section className="contact contact-4" id="contact-4">
            <div className="container">
                <div className="contact-panel contact-panel-3">

                <div className="heading heading-6">
                    <p className="heading-subtitle">Let us prepare you a proper quotation</p>
                    <h2 className="heading-title">Are you ready to gain Independence through the power of solar energy!</h2>
                    <p className="heading-desc">We first gather all necessary information required for us to prepare you the best solution and quotation for your new solar system.</p>
                    <div className="advantages-list-holder">
                        <div className="row"> 
                       
                        </div>
                    </div>
                    <div className="contact-action"><a className="btn btn--primary" href="/about-khutla-green">about khutla <i className="energia-arrow-right"></i></a><a className="btn btn--bordered btn--white" href="/khutla-green-services">our core services</a></div>
                    <div className="contact-quote contact-quote-3"> <img src="assets/images/icons/noteicon-2.png" alt="icon"/>
                        <p>Receive a close quote when you fill out this form. Or, give us a call: <a href="tel:01061245741">(+27) 73 1480 138</a></p>
                    </div>
                    </div>

                    <QouteForm />

                </div>
   
            </div>
        </section>
    );
}
export default QuoteSection;