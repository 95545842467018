import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';

import Nav from '../nav/index';
import TopBar from '../topbar';


const Header = props => {

    return(
        <header className="header header-light header-topbar header-topbar1 header-shadow" id="navbar-spy">
    
            <TopBar />
            <Nav />

        </header>
    ); 
}

export default Header;