import React from 'react'

const Slide = (props) => {
    const {icon, title, content} = props
 
    return (

        <div>
            <div className="feature-panel-holder" data-hover="">
                <div className="feature-panel">
                <div className="feature-icon"><i className={ icon }></i></div>
                <div className="feature-content">
                    <h4>{ title } </h4>
                    <p>{ content }</p>
                </div><a href="/about-khutla-green"><i className="energia-arrow-right"></i> <span>explore more</span> </a>
                </div>
            
            </div>
        </div>
    )
}

export default Slide
