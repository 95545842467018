import React from 'react';
import ContactForm from '../../components/contact-form';
import './styles.css';

const ContactSection = () => {

    return(
    <React.Fragment>
            <section className="testimonial testimonial-5 bg-overlay bg-overlay-white2 bg-section">
               
                <div className="container">
               
                <div className="contact-panel contact-panel-2">
                    <div className="row">
                    
                    <div className="col-12 col-lg-5 img-card-holder">
                        <div className="img-card img-card-2 bg-overlay bg-overlay-theme bg-section" style={{ backgroundImage: "url('/assets/images/contact/2.jpg')" }}>
                        
                        <div className="card-content">
                            <div className="content-top">
                            <p>Are you still unclear about something, Please don't hesitate to call us.</p>
                            </div>
                            <div className="content-bottom">
                            <ul className="list-unstyled contact-infos"> 
                                <li className="contact-info"><i className="energia-phone-Icon"></i>
                                <p>Contact Line: <a href="tel:123-456-7890">(+27) 731480138</a></p>
                                </li>
                                <li className="contact-info"><i className="energia-location-Icon"></i>
                                <p>Location: <a href="mailto:info@energia.com">1 Valsbaai Street, Germiston,1450, Johannesburg.		</a></p>
                                </li>
                                <li className="contact-info"><i className="energia-clock-Icon"></i>
                                <p>Mon - Fri: 8:00 - 17:00	</p>
                                </li>
                            </ul><a className="btn btn--white" href="/about-khutla-green">About us <i className="energia-arrow-right"></i></a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-7">
                        <div className="contact-card">

                            <ContactForm />
                    
                        </div>
                    </div>
                    </div>
                    
                </div>
                
            
        
         </div>
      
      </section>
    </React.Fragment>);
}
export default ContactSection;