import React from 'react';
import Slide from './Slide';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = () => {

    const owlOptions = {

        loop: true,
        autoplay: true,
        margin: 25,
        nav: false,
        dots: true,
        dotsSpeed: "800",
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        rtl: "true",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1000: {
                items: 4,
                //center: $Carousel.data('center'),
            }
        }
      };
    return(
    <React.Fragment>

    
        <OwlCarousel className="carousel owl-carousel carousel-dots" loop {...owlOptions}>
            <Slide icon="flaticon-024-energy" title="Save Your Money" content="Save money on utilities or increase the value of your home by installing solar panels as a great option." loop/>
            <Slide icon="flaticon-028-greenhouse" title="Home Is Energy" content="Everyday the sun provides us with abundance of free energy by placing solar panels on your roof." loop/>
    
        </OwlCarousel>
                       
 
    </React.Fragment>
    );
}

export default Slider;