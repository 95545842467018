import React from "react"; 
import QuoteSection from "../../sections/quote-section";

const QuotePage = () => {


    return(
    <React.Fragment> 
        
        <QuoteSection />
    

    </React.Fragment>);
} 

export default QuotePage;