import React from 'react';
import Header from '../components/header/index';
import SearchBar from '../components/searchbar';
import { Outlet } from "react-router-dom";
import Footer from '../components/footer';

const HomeLayout = props => {

    const moveToTop = (e) => 
    {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return false;
    }
    return(
        <div className="wrapper clearfix" id="wrapperParallax">
            <Header />
            <SearchBar />
            <Outlet />
            <Footer />

            <div onClick={ e => moveToTop(e)} className="back-top show" id="back-to-top" data-hover=""><i className="energia-arrow-up" ></i></div>

        </div>
    );
}

export default HomeLayout;