import React from 'react';
import Slider from '../../components/slider/recent-projects';

const RecentProjectsSection = (props) => {

    return(
        <section className="projects projects-modern projects-modern-2" id="projects-modern-1">
                <div className="container">
                <div className="row"> 
                    <div className="col-12 col-lg-6 offset-lg-3">
                    <div className="heading heading-5 text-center">
                        <p className="heading-subtitle">Innovation, Quality And Continuous Improvement</p>
                        <h2 className="heading-title">Latest Projects, Solutions And Energy Supplies</h2>
                    </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-12">

                        <Slider />
                    </div>
                </div>
                
            </div>

        </section>
    );

}

export default RecentProjectsSection;