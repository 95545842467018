import React from 'react';

const BreadCrumb = (props) => {

    const { breadcrumb } = props;

    //const [index ,setIndex] = useState(0);

    return(

       
  
        <div className="breadcrumb-wrap">
            <div className="container">
                <ol className="breadcrumb d-flex">

                    {breadcrumb
                        .filter((page) => !page.active)
                        .map((page) => (
                            <li key={page.label} className="breadcrumb-item"><a href={ page.link}>{ page.label } </a></li>
                    ))}

                    {breadcrumb
                        .filter((page) => page.active)
                        .map(( page) => (
                            <li key={page.label} className="breadcrumb-item active" aria-current="page">{ page.label } </li>
                    ))}

                </ol>
            
            </div>
        </div>
    );
}
                            
export default BreadCrumb;