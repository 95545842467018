import React from 'react';
import Banner from '../../components/Banner';

const FAQsPage = () => 
{


    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "Company", link:"/about"},
        { "label": "FAQs", active: true }
    ];

    return(
        <React.Fragment>

            <Banner title="FAQs" description="Khutla green" breadcrumb={ breadcrumb } />


            <section class="faqs faqs-2" id="faqs-1">
                <div class="container">
                <div class="row"> 
                    <div class="col-12 col-lg-6 offset-lg-3"> 
                    <div class="heading heading-18 text-center">
                       
                        <h2 class="heading-title">Frequently Asked Questions</h2>
                    </div>
                    </div>
                </div>
                <div class="accordion accordion-2" id="accordion03">
                    <div class="row">
                    <div class="col-12 col-lg-6">
                        <div>
                        <div class="card">
                            <div class="card-heading"><a class="card-link collapsed" data-hover="" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse01-1" href="#collapse01-1">Which Plan Is Right For Me?</a></div>
                            <div class="collapse" id="collapse01-1" data-bs-parent="#accordion03">
                            <div class="card-body">With any financial product that you buy, it is important that you know you are getting the best advice from a reputable company as often you will have to</div>
                            </div>
                        </div>
                        </div>
                        <div>
                        <div class="card active-acc">
                            <div class="card-heading"><a class="card-link  " data-hover="" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse01-3" href="#collapse01-3">How many options do I have?</a></div>
                            <div class="collapse show" id="collapse01-3" data-bs-parent="#accordion03">
                            <div class="card-body">We provide three packages for your convinience, Starter Plan, Basic Plan, and Advanced Plan which allows you to use all electirity euquipment iin your home</div>
                            </div>
                        </div>
                        </div>
                        <div>
                       
                        </div>
                    </div>


                    <div class="col-12 col-lg-6">
                        <div>
                        <div class="card">
                            <div class="card-heading"><a class="card-link collapsed" data-hover="" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapse01-2" href="#collapse01-2">How long is the warranty? </a></div>
                            <div class="collapse" id="collapse01-2" data-bs-parent="#accordion03">
                            <div class="card-body">We provide different kind of packages for with different warranties. Our warranty options start from 6 months, 1 year, 5 years and even 7 years, The higher the warranty period the higher the cost.</div>
                            </div>
                        </div>
                        </div>
                        <div>
                 
                        </div>
                    </div>
                    </div>
                </div>
                
                </div>
                <div class="container">
                <div class="more-faqs">
                    <p>Sustainable, reliable & affordable energy systems, <a href="/khutla-green-services">find your solutions now! </a></p>
                </div>
                </div>
            
            </section>

        </React.Fragment>
    );
}


export default FAQsPage;