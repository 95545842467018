import React, { useState } from 'react';

const QouteForm = () =>{

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ phone, setPhone] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [additionalInfo, setAddtionalInfo] = useState("");


    const handleSubmit = (event) => {
      event.preventDefault();
      alert(`The name you entered was: ${name}`)
    }

    return(
        <React.Fragment>
            <div className="contact-card">
              <div className="contact-body">
                <h5 className="card-heading">Request A Quote</h5>
                <p className="card-desc">Fill in the information below for us to provide you with an estimate to ensure the best quality service.</p>
               
                <form className="contactForm" onSubmit={handleSubmit}>
                  <div className="mb-20">
                    <div className="row">
                     
                  
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-usage">Monthly electric usage in kWh?</label>
                        <input className="form-control" type="text" id="contact-usage" name="contact-usage" placeholder="1254 KWH" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="select-3">Solar system type?</label>
                        <select className="form-control" id="select-3">
                          <option value="default">OffGrid</option>
                          <option value="AL">OnGrid</option>
                       
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="select-4">Solar panels place?</label>
                        <select className="form-control" id="select-4">
                          <option value="default">huge farm</option>
                          <option value="AL">small farm</option>
                          <option value="SH">Standalone house</option>
                          <option value="SS">Sectional Scheme</option>
                          <option value="TH">Town House</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="select-5">Materials on your roof?</label>
                        <select className="form-control" id="select-5">
                          <option value="default">comp shingle</option>
                          <option value="AL">roof shingle</option>
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="form-label" for="contact-projects">Tell us about your project </label>
                        <textarea className="form-control" id="contact-projects" placeholder="Add other data" name="contact-projects" cols="30" rows="10"> </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mb-20">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-first-name">First name</label>
                        <input className="form-control" type="text" id="contact-first-name" name="contact-first-name" placeholder="Mahmoud" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-last-name">Last name</label>
                        <input className="form-control" type="text" id="contact-last-name" name="contact-last-name" placeholder="Adel" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-phone">Phone</label>
                        <input className="form-control" type="text" id="contact-phone" name="contact-phone" placeholder="Phone" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-email">Email</label>
                        <input className="form-control" type="email" id="contact-email" name="contact-email" placeholder="Email" required=""/>
                      </div>
                    </div>
                  </div>
                  <div className="mb-20">
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label">Preferred Contact Method</label>
                        <div className="custom-radio-group" id="custom-radio-group">
                          <div className="custom-control">
                            <input className="custom-control-input" type="radio" id="customRadioInline1" name="customRadioInline1"/>
                            <label for="customRadioInline1">all</label>
                          </div>
                          <div className="custom-control">
                            <input className="custom-control-input" type="radio" id="customRadioInline2" name="customRadioInline1"/>
                            <label for="customRadioInline2">email</label>
                          </div>
                          <div className="custom-control">
                            <input className="custom-control-input" type="radio" id="customRadioInline3" name="customRadioInline1"/>
                            <label for="customRadioInline3">phone</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="col-12">
                        <label className="form-label" for="contact-address">Street address</label>
                        <input className="form-control" type="text" id="contact-address" name="contact-address" placeholder="write street address" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-city">City</label>
                        <input className="form-control" type="text" id="contact-city" name="contact-city" placeholder="write city" required=""/>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="select-6">Province</label>
                        <select className="form-control" id="select-6">
                          <option value="default">Gauteng</option>
                          <option value="AL">Limpopo</option>
                          <option value="AL">Kwazulu Natal</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label" for="contact-zip">ZIP code</label>
                        <input className="form-control" type="text" id="contact-zip" name="contact-zip" placeholder="0001" required=""/>
                      </div>
                    </div>
                  </div>
                  <div> 
                    <div className="row"> 
                     
                      <div className="col-12"> 
                        <div className="custom-radio-group" id="custom-radio-group-2">
                          <div className="custom-control">
                            <input className="custom-control-input" type="radio" id="customRadioInline4" name="customRadioInline4"/>
                            <label for="customRadioInline4">i agree to the <a href="javascript:void(0)">terms and conditions </a></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div> 
                    <div className="row">
                      <div className="col-12">
                        <button className="btn btn--secondary w-100">submit request <i className="energia-arrow-right"></i></button>
                      </div>
                      <div className="col-12">
                        <div className="contact-result"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
     
            </div>
        </React.Fragment>
    );
}
export default QouteForm;