import React, { useContext} from 'react';
import './style.css';

import { WarrantyOptionContext } from '../../../sections/pckages';

const Slide = (props) => {
    const {title , categoryOption} = props
    const { setSelectedWarrantyOption } =  useContext(WarrantyOptionContext);

 
    const changeCateogryOption = (e, categoryOption) => {

      let currentElement = e.target.parentElement.querySelector("*").closest(".career-item-wrap");
      let wraps =  document.querySelectorAll('.career-item *');

      wraps.forEach((element) => {
        element.classList.remove('active-block');
      });
      
      currentElement.classList.add("active-block");
  
      setSelectedWarrantyOption(categoryOption);
    }

    return (     
      <div className="career-item">
        <div className="career-item-wrap" onClick={ e => changeCateogryOption(e, categoryOption) } >
          <div className="career-meta">
            <p className="career-type">warranty period</p>
            <p className="career-place">{ title} </p>
          </div>
          
        </div>
      
      </div>

    )
}

export default Slide
