import React from "react";
import Slider from "../../components/slider/home";
import HomeAboutSection from "../../sections/about";
import Features from "../../sections/Features";
import Services from "../../sections/home-services";

const HomePage = () => {

    return(
    <React.Fragment>

        <Slider />

        <HomeAboutSection />

        <Features showVideo={true} />

        <Services />

    </React.Fragment>);
} 

export default HomePage;