import React from 'react-dom';
import Slider from '../../components/slider/Features/index';

import './styles.css';

const Features = (props) => {

    const { showVideo } = props;

    return (
        <section className="features features-1 bg-overlay bg-overlay-theme2 bg-section" id="features-1" style={{ backgroundImage: "url('/assets/images/background/2.jpg')" }}>
            
                <div className="container">
                    <div className="heading heading-2 heading-light heading-light2">
                        <div className="row">
                        <div className="col-12 col-lg-5">
                            <p className="heading-subtitle">Sustainable, Reliable & Affordable Energy!</p>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12 col-lg-5">
                            <h2 className="heading-title">Providing Value To Our Clients Through Ongoing Product & Innovation.</h2>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-1">
                            <p className="heading-desc">While improving the yield and performance of solar energy products, our PV industry experience enables us to provide in-depth material sourcing, financing and supply chain expertise for every step.</p>
                            <p className="heading-desc">Raw polycrystalline silicon for PV manufacturing. Offered in various grades and formats including chunks, chips, powder and ingot.</p>
                            <div className="actions-holder">
                                <a className="btn btn--primary btn--inversed" href="/contact-khutla-green">
                                get started<i className="energia-arrow-right"></i></a><a className="btn btn--bordered btn--white" href="/khutla-green-pricing">explore our plans</a></div>
                        </div>
                        </div>

                    </div>


                   <Slider />
               
                
                { showVideo && (
                    <div className="row"> 
                        <div className="col-12 col-lg-4">
                            <div className="more-features"> 
                                <p>If you have any questions or need help, feel free to contact our team, or you can call us any time <a href="tel:27839482209"><br/>(27) 731480138</a></p>
                                <a className="btn btn--bordered btn--white" href="/khutla-green-pricing">
                                free estimate<i className="energia-arrow-right"></i></a>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8">
                            <div className="video video-1 bg-overlay bg-overlay-video" id="video-1">
                                <div className="bg-section"><img src="/assets/images/video/1.jpg" alt="background"/></div><a className="popup-video btn-video" href="https://youtube.com/shorts/YX0UNly9kWM?feature=share"> <i className="fas fa-play"></i><span>watch our video!</span></a>
                            
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </section>
      
    )
}

export default Features;