import React from 'react';
import Slide from './Slide';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = () => {

  const Owloptions = {
    loop: true,
    autoplay: true,
    margin: 25,
    nav: false,
    dots: true,
    dotsSpeed: "3000",
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    rtl: "true",
    responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1000: {
                items: 3,
                //center: $Carousel.data('center'),
            }
        }
    };
    //image, category, title, content, link
    const link = "/khutla-green-gallery";

    const recentProjects = [
        {
            image: "/assets/images/projects/modern/1.jpg", 
            category: "energy, green energy, solar", 
            title: "Addressing Wind Energy Innovation Challenges", 
            content: "The great thing about solar panels is that they do not requiremaintenance. However, it is still important to get them checked regularly to ensure safe", 
            link: "/khutla-green-gallery"
        },
        {
            image: "/assets/images/projects/modern/2.jpg", 
            category: "energy, green energy, solar", 
            title: "Energy Innovation Challenges", 
            content: "As wind turbines increase in size &amp; height to capture more energy, and as wind power plants with many turbines spread out over greater areas", 
            link: "/khutla-green-gallery"
        },
        {
            image: "/assets/images/projects/modern/3.jpg", 
            category: "energy, green energy, solar", 
            title: "Charging Infrastructures", 
            content: "If you own an electric vehicle (EV), the recurring question you’ll get is: how easy is it to charge the battery on the road? Clearly, people simply want to get", 
            link: "/khutla-green-gallery"
        },
        {
            image: "/assets/images/projects/modern/4.jpg", 
            category: "energy, green energy, solar", 
            title: "Renewable Energy", 
            content: "Government tracks the nation’s changing attitudes towards energy infrastructure closely, with quarterly surveys led by the Department of Business", 
            link: "/khutla-green-gallery"
        }
    ];

    return(
    <React.Fragment>

        <OwlCarousel className="carousel owl-carousel carousel-dots owl-loaded owl-drag" {...Owloptions}>
            {recentProjects.map( (project) => (                
                <Slide key={project.title} title={project.title} image={project.image}  link={ link }  category={project.category}  content={project.content}/>
             ))
            } 
        </OwlCarousel>
    

    </React.Fragment>
    );
}

export default Slider;