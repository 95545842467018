import React from 'react';
import Banner from '../../components/Banner';
import ServicesSecton from '../../sections/services';
import './styles.css';

const ServicesPage = () => 
{

    const breadcrumb = [
        { "label": "Home", link:"/"},
        { "label": "Company", link:"/about"},
        { "label": "Serices", active: true }
    ];

    return(
        <React.Fragment>

            <Banner 
                title="Khutla Green Services" 
                description="We offer products, solutions, and services across the entire energy value chain. We support our customers on their way to a more sustainable future." 
                breadcrumb={ breadcrumb } />


                <ServicesSecton />

        </React.Fragment>
    );
}
export default ServicesPage;