import React, { useState, useEffect, createContext } from 'react';

import  Slider from '../../components/slider/warranty/index';

import './styles.css';

export const WarrantyOptionContext = createContext();

const PricingPackages = () => {

    const [selectedWarrantyOption, setSelectedWarrantyOption] = useState(0);

    const warrantyPackageList = [
        {
            title: "6 Months Warranty",
            content: "Only get covered for only 6 months, cheaper equipment used", 
            categoryOption: 0
        },
        {
            title: "1 Year Warranty",
            content: "Get covered for one year only", 
            categoryOption: 1
        },
        {
            title: "5 Years Warranty",
            content: "Get covered for five years", 
            categoryOption: 2
        },
        {
            title: "7 Years Warranty",
            content: "Get covered for seven years", 
            categoryOption: 3
        }
    ];

    const pricingPlansOptions = { 
        0: [
        {
            title: "Starter Plan",
            class:"",
            description: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            content: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            cost: "R 40 700.00",
            warranty: "6 Months Warranty",
            inlcudes: [
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "3 KVA Invetor",
                "4,8 KWh Battery",
                "1,5 KW Panels"
            ]

        },
        {
            title: "Basic Plan",
            class:"active",
            description: "Do all basic activivitues around the house including cooking using solar power",
            content: "Do all basic activivitues around the house including cooking using solar power",
            cost: "TBA",
            warranty: "6 Months Warranty",
            inlcudes: [
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "5 KVA Invetor",
                "7 KWh Battery",
                "2,2 KW Panels"
            ]

        },
        {
            title: "Advanced Plan",
            class:"",
            description: "Perform all your activitues including switching on your gyser, using solar power",
            content: "Perform all your activitues including switching on your gyser, using solar power",
            cost: "TBA",
            warranty: "6 Months Warranty",
            inlcudes: [
                "Gyser",
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "8 KVA Invetor",
                "10 KWh Battery",
                "2,2 KW Panels"
            ]

        }
    ],
    1 : [
        {
            title: "Starter Plan",
            class:"",
            description: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            content: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            cost: "TBA",
            warranty: "1 Year Warranty",
            inlcudes: [
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "3 KVA Invetor",
                "4,8 KWh Battery",
                "1,5 KW Panels"
            ]

        },
        {
            title: "Basic Plan",
            class:"active",
            description: "Do all basic activivitues around the house including cooking using solar power",
            content: "Do all basic activivitues around the house including cooking using solar power",
            cost: "TBA",
            warranty: "1 Year Warranty",
            inlcudes: [
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "5 KVA Invetor",
                "7 KWh Battery",
                "2,2 KW Panels"
            ]

        },
        {
            title: "Advanced Plan",
            class:"",
            description: "Perform all your activitues including switching on your gyser, using solar power",
            content: "Perform all your activitues including switching on your gyser, using solar power",
            cost: "TBA",
            warranty: "1 Year Warranty",
            inlcudes: [
                "Gyser",
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "8 KVA Invetor",
                "10 KWh Battery",
                "2,2 KW Panels"
            ]

        }
    ],
    2: [
        {
            title: "Starter Plan",
            class:"",
            description: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            content: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            cost: "TBA",
            warranty: "5 Years Warranty",
            inlcudes: [
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "3 KVA Invetor",
                "4,8 KWh Battery",
                "1,5 KW Panels"
            ]

        },
        {
            title: "Basic Plan",
            class:"active",
            description: "Do all basic activivitues around the house including cooking using solar power",
            content: "Do all basic activivitues around the house including cooking using solar power",
            cost: "TBA",
            warranty: "5 Years Warranty",
            inlcudes: [
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "5 KVA Invetor",
                "7 KWh Battery",
                "2,2 KW Panels"
            ]

        },
        {
            title: "Advanced Plan",
            class:"",
            description: "Perform all your activitues including switching on your gyser, using solar power",
            content: "Perform all your activitues including switching on your gyser, using solar power",
            cost: "TBA",
            warranty: "5 Years Warranty",
            inlcudes: [
                "Gyser",
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "8 KVA Invetor",
                "10 KWh Battery",
                "2,2 KW Panels"
            ]

        }
    ],
    3: [
        {
            title: "Starter Plan",
            class:"",
            description: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            content: "This is a basic installation, keeping you from the dark during power outages or load shedding",
            cost: "TBA",
            warranty: "7 Years Warranty",
            inlcudes: [
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "3 KVA Invetor",
                "4,8 KWh Battery",
                "1,5 KW Panels"
            ]

        },
        {
            title: "Basic Plan",
            class:"active",
            description: "Do all basic activivitues around the house including cooking using solar power",
            content: "Do all basic activivitues around the house including cooking using solar power",
            cost: "TBA",
            warranty: "7 Years Warranty",
            inlcudes: [
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "5 KVA Invetor",
                "7 KWh Battery",
                "2,2 KW Panels"
            ]

        },
        {
            title: "Advanced Plan",
            class:"",
            description: "Perform all your activitues including switching on your gyser, using solar power",
            content: "Perform all your activitues including switching on your gyser, using solar power",
            cost: "TBA",
            warranty: "7 Years Warranty",
            inlcudes: [
                "Gyser",
                "Stove",
                "3 TVs",
                "3 Fridges",
                "All Ligths",
                "Microwave"
            ],
            specification: [
                "8 KVA Invetor",
                "10 KWh Battery",
                "2,2 KW Panels"
            ]

        }
    ]
    };

    let pricingPlans =  pricingPlansOptions[selectedWarrantyOption];

    useEffect(() => {
        if(selectedWarrantyOption) {
            pricingPlans = pricingPlansOptions[selectedWarrantyOption];
        }
    }, [selectedWarrantyOption]);



    return(
        <WarrantyOptionContext.Provider value={{ selectedWarrantyOption, setSelectedWarrantyOption }}>
        <section className="pricing pricing-1" id="pricing-1">
            <div className="container">
                
                <div className="row"> 
                    <div className="col-12 col-lg-6 offset-lg-3"> 
                        <div className="heading heading-16 text-center">
                            <p className="heading-subtitle">Pricing And Plans</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 careers">
                        <Slider  packages={warrantyPackageList } selectedWarrantyOption={selectedWarrantyOption} />
                    </div>
                </div>

                <div className="row">
                        { pricingPlans.map( (plan) => (
                        
                                <div className="col-12 col-lg-4 d-flex" key={plan.title} >
                                    <div className="price-table">
                                        <div className={ "pricing-panel " + plan.class} >
                                        <div className="pricing-body">
                                            <div className="pricing-heading">
                                            <h4 className="pricing-title">{ plan.title} </h4>
                                            <p className="pricing-desc">{ plan.description }</p>
                                            </div>
                                            <div className="pricing-price">
                                                    <p> <span className="currency">{plan.cost} </span><span className="time"> {plan.warranty} </span> </p>
                                                
                                                <a className="btn btn--secondary" href="/request-quote">enquire now <i className="energia-arrow-right"></i></a>
                                            </div>
                                            <div className="pricing-list"> 
                                                <p className="pricing-sub-heading">Includes</p>
                                                <ul className="advantages-list list-unstyled">
                                                    { plan.inlcudes.map( (item) => ( <li key={ item } >{ item} </li> ))  }
                                                </ul>
                                                </div>
                                                <br/>
                                                <div className="pricing-list"> 
                                                    <p className="pricing-sub-heading">specification</p>
                                                <ul className="advantages-list list-unstyled">
                                                    { plan.specification.map( (item) => ( <li key={ item } >{ item} </li> ))  }
                                                </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                
                </div>
                
                <div className="container">
                <div className="more-prices">
                    <p>Sustainable, reliable & affordable energy systems, <a href="/khutla-green-services">find your solutions now! </a></p>
                </div>
            </div>
        </section>
        </WarrantyOptionContext.Provider>
    );

}

export default PricingPackages;